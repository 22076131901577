






































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Action, State} from 'vuex-class';
import _ from 'lodash';

@Component({
  name: 'PortalStepperHeader',
})
export default class PortalStepperHeader extends Vue {
  @Prop() private value!: string;
  @Prop() private step!: any;
  @Prop() private steps!: any;
  @Prop() private afterUW!: boolean;
  @State private cms: any;
  @Action('app/setTotalSteps') private setTotalSteps: any;

  private prevScrollpos: number = window.pageYOffset;
  private stepperVisible: boolean = true;

  get stepValues() {
    return _.values(this.steps);
  }

  get getStepIndex() {
    let ref = _.find(this.steps, (step) => !step.prev);
    if (!ref) {
      return 0;
    }
    let found = false;
    let counter = 0;
    while (!found) {
      counter++;
      if (ref.ref === this.step.ref) {
        found = true;
      }
      if (found || !ref.next) {
        break;
      } else {
        ref = this.steps[ref.next];
      }
    }
    return counter;
  }

  get getHeight() {
    return ((this.$vuetify.breakpoint.xsOnly) ? 50 : 75) + 'px';
  }

  private back() {
    const prevStep = this.step.prev;
    if (prevStep) {
      this.$emit('input', prevStep);
    } else {
      this.$router.push({name: 'landing'});
    }
  }

  get isBackVisible() {
    return this.step.next && (!this.afterUW || this.step.prev);
  }

  private handleScroll() {
    if (this.$vuetify.breakpoint.xsOnly) {
      const currentScrollPos = window.pageYOffset;
      // this.stepperVisible = currentScrollPos < 14 || this.prevScrollpos > currentScrollPos;
      this.stepperVisible = currentScrollPos < 100; // Simulate same as top bar instead
      this.prevScrollpos = currentScrollPos;
    }
  }

  private created() {
    window.addEventListener('scroll', this.handleScroll);
    this.setTotalSteps(this.steps.length);
  }
}
