import { render, staticRenderFns } from "./PortalStepper.vue?vue&type=template&id=5d66645a&scoped=true&"
import script from "./PortalStepper.vue?vue&type=script&lang=ts&"
export * from "./PortalStepper.vue?vue&type=script&lang=ts&"
import style0 from "./PortalStepper.vue?vue&type=style&index=0&id=5d66645a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d66645a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VStepper } from 'vuetify/lib/components/VStepper';
installComponents(component, {VProgressLinear,VStepper})
