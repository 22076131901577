













import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {State, Action, Getter} from 'vuex-class';
import PortalStepperHeader from './PortalStepperHeader.vue';
import PortalStepperItems from './PortalStepperItems/Index.vue';
import {IdleService} from '@/services/IdleService';
import {CMSContentService} from '@/services/CMSService';
import {default as GA, IODPageViewData} from '@/utils/GAUtil';
import _ from 'lodash';
import EventBus from '@/common/EventBus';

@Component({
  name: 'PortalStepper',
  components: {
    PortalStepperHeader,
    PortalStepperItems,
  },
})
export default class PortalStepper extends Vue {
  @State private proposal: any;
  @State private app: any;
  @State private cms: any;
  @Prop() private contentReady?: boolean;
  @Action('app/setActiveStep') private setActiveStep: any;
  @Action('app/setPeProgress') private setPeProgress!: (payload: boolean) => void;
  @Getter('quotation/hasPastCoverageStartDate') private hasPastCoverageStartDate: any;

  private currentStep: string = '';
  private currentStepIndex: number = 1;
  private currentStepObj: any = {};
  private afterUW: boolean = false;
  private steps: any = {};

  public stepHandler() {
    const first = this.app.isSavedProposal ? _.find(this.steps, (step) => step.ref === this.getStepForSavedProposal()) : _.find(this.steps, (step) => !step.prev);
    this.currentStep = this.app.activeStep || first.ref;
    this.currentStep = first.ref;
    this.currentStepObj = this.steps[this.currentStep];
    this.currentStepIndex = this.currentStepObj.index;
  }

  private async created() {
    const skip: any[] = [];

    if (this.app?.config?.shiftMappings?.SKIP_STEPPER) {
      skip.push(...this.app?.config?.shiftMappings?.SKIP_STEPPER);
    }
    try {
      const res = await CMSContentService.getBasicInfo(this.app.targetSegment, _.get(this.app, 'language', 'en'));
      if (!((res as any[]).length > 0)) {
        skip.push('basicInfo');
      }
    } catch (err) {
      skip.push('basicInfo');
    }
    this.initSteps(skip);

    EventBus.$on('PE_IN_PROGRESS', () => {
      this.setPeProgress(true);
    });
    EventBus.$on('PE_COMPLETED', () => {
      this.setPeProgress(false);
    });
  }

  private destroyed() {
    EventBus.$off('PE_IN_PROGRESS');
    EventBus.$off('PE_COMPLETED');
  }

  private initSteps(skip) {
    this.afterUW = this.$route.name === 'review';

    this.steps = this.afterUW ?
      {
        summary: {index: 1, type: 'Summary', ref: 'summary', img: 'summary', prev: null, next: 'payment', name: 'stepper.label.summary'},
        payment: {index: 2, type: 'Payment', ref: 'payment', img: 'payment', prev: 'summary', next: null, name: 'stepper.label.payment'}
      } : {
        basicInfo: {index: 1, type: 'BasicInfo', ref: 'basicInfo', img: 'basicData', prev: null, next: 'configure', name: 'stepper.label.basic'},
        configure: {index: 2, type: 'Configure', ref: 'configure', img: 'configuration', prev: 'basicInfo', next: 'coverageDetails', name: 'stepper.label.configure'},
        coverageDetails: {index: 3, type: 'coverageDetails', ref: 'coverageDetails', img: 'coverageDetails', prev: 'configure', next: 'verification', name: 'stepper.label.details'},
        verification: {index: 4, type: 'Verification', ref: 'verification', img: 'verification', prev: 'coverageDetails', next: 'summary', name: 'stepper.label.verification'},
        summary: {index: 5, type: 'Summary', ref: 'summary', img: 'summary', prev: 'verification', next: 'payment', name: 'stepper.label.summary'},
        payment: {index: 6, type: 'Payment', ref: 'payment', img: 'payment', prev: 'summary', next: null, name: 'stepper.label.payment'}
      };

    skip.forEach((toSkip) => {
      const step = this.steps[toSkip];
      if (step) {
          const next = this.steps[step.next];
          const stepsKey = Object.keys(this.steps);
          const indexOfStep = stepsKey.indexOf(toSkip);
          const nextKey = stepsKey[indexOfStep + 1];
          const prevKey = stepsKey[indexOfStep - 1];

          if (nextKey && prevKey) {
            this.steps[prevKey].next = this.steps[toSkip].next;
            this.steps[nextKey].prev = this.steps[toSkip].prev;
          }
          if (next) {
            next.prev = step.prev;
            delete this.steps[toSkip];
          }
      }
    });

    this.stepHandler();
  }

  private getStepForSavedProposal() {
    if (this.hasPastCoverageStartDate()) {
        return 'configure';
    } else {
        return 'summary';
    }
  }

  @Watch('currentStep')
  private onStepClicked() {
    IdleService.resetTimer();
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.currentStepObj = this.steps[this.currentStep];
    this.setActiveStep(this.currentStepObj?.index);
    this.currentStepIndex = this.currentStepObj?.index;

    // GA: log current step started
    const data: IODPageViewData = {
      pagePath: `/portal/${this.currentStepObj.ref}`,
      pageTitle: this.currentStepObj.type
    };
    GA.ODLogPageView(data);
  }
}
